<div class="col-12">
  <form novalidate (ngSubmit)="emitOnSubmit()" [formGroup]="stripeForm">

    <div class="form-group row">
      <label  class="col-sm-4 col-form-label">{{'sale.account' | translate }}</label>
      <div class="col-sm-8">
        <input type="text" formControlName="name" placeholder="{{'sale.account' | translate }}" class="form-control" (ngModelChange)="checkValidation()">
      </div>
    </div>

    <div class="form-group row">
      <label  class="col-sm-4 col-form-label">{{'sale.email' | translate }}</label>
      <div class="col-sm-8">
        <input type="email" formControlName="email" placeholder="{{'sale.email' | translate }}" class="form-control" (ngModelChange)="checkValidation()">
      </div>
    </div>

    <!--<div class="form-group row align-items-center" *ngIf="availablePaymentMethods.length > 1">
      <label class="col-sm-4 col-form-label">{{'sale.payment_method' | translate }}</label>
      <div class="col-sm-8">
        <div class="radio c-radio d-flex">
          <label class="mb-0" for="paymentMethodTypeCard">
            <input type="radio" name="paymentMethodType" id="paymentMethodTypeCard" formControlName="paymentMethodType" [value]="PaymentMethodType.CARD">
            <span class="fa fa-circle"></span>{{'sale.payment_method_card' | translate}}
          </label>
        </div>
        <div class="radio c-radio d-flex mt-1">
          <label class="mb-0" for="paymentMethodTypeSepa">
            <input type="radio" name="paymentMethodType" id="paymentMethodTypeSepa" formControlName="paymentMethodType" [value]="PaymentMethodType.SEPA_DEBIT">
            <span class="fa fa-circle"></span>{{'sale.payment_method_sepa' | translate}}
          </label>
        </div>
      </div>
    </div>-->
    
    <!--<div id="card-element" class="stripe-field mb-3" [hidden]="stripeForm.controls['paymentMethodType'].value != PaymentMethodType.CARD"></div>
    <div id="sepa-element" class="stripe-field mb-3" [hidden]="stripeForm.controls['paymentMethodType'].value != PaymentMethodType.SEPA_DEBIT"></div>-->
    <div id="payment-element" class="mb-3"></div>

    <div class="method-agreement-panel mt-3 mb-3" *ngIf="stripeForm.controls['paymentMethodType'].value == PaymentMethodType.CARD && mode == 'manage'">
      {{'sale.card_add_method_agreement' | translate}}
    </div>
    <div class="method-agreement-panel mt-3 mb-3" *ngIf="stripeForm.controls['paymentMethodType'].value == PaymentMethodType.CARD && mode == 'pay'">
      <div class="mb-1">{{'sale.card_pay_agreement_1' | translate: {'beneficiary': beneficiary} }}</div>
      <div>{{'sale.card_pay_agreement_2' | translate: {'beneficiary': beneficiary} }}</div>
      <div>{{'sale.card_pay_agreement_3' | translate}}</div>
    </div>
    <div class="method-agreement-panel mt-3 mb-3" *ngIf="stripeForm.controls['paymentMethodType'].value == PaymentMethodType.SEPA_DEBIT">
      <div class="mb-1">{{'sale.sepa_pay_agreement_1' | translate: {'beneficiary': beneficiary} }}</div>
      <div class="mb-1">{{'sale.sepa_pay_agreement_2' | translate}}</div>
      <div>{{'sale.sepa_pay_agreement_3' | translate}}</div>
      <div>{{'sale.sepa_pay_agreement_4' | translate}}</div>
      <div>{{'sale.sepa_pay_agreement_5' | translate}}</div>
    </div>
    <div class="method-agreement-panel mt-3 mb-3" *ngIf="stripeForm.controls['paymentMethodType'].value == PaymentMethodType.PAYPAL">
      <div class="mb-1">{{'sale.paypal_pay_agreement_1' | translate: {'beneficiary': beneficiary} }}</div>
      <div>{{'sale.paypal_pay_agreement_2' | translate: {'beneficiary': beneficiary} }}</div>
      <div>{{'sale.paypal_pay_agreement_3' | translate}}</div>
      <div>{{'sale.paypal_pay_agreement_4' | translate}}</div>
    </div>
    <div class="method-agreement-panel mt-3 mb-3" *ngIf="stripeForm.controls['paymentMethodType'].value == PaymentMethodType.KLARNA">
      <div class="mb-1">{{'sale.klarna_pay_agreement_1' | translate: {'beneficiary': beneficiary} }}</div>
      <div>{{'sale.klarna_pay_agreement_2' | translate: {'beneficiary': beneficiary} }}</div>
      <div>{{'sale.klarna_pay_agreement_3' | translate}}</div>
      <div>{{'sale.klarna_pay_agreement_4' | translate}}</div>
    </div>
    <div class="method-agreement-panel mt-3 mb-3" *ngIf="stripeForm.controls['paymentMethodType'].value == PaymentMethodType.SATISPAY">
      <div class="mb-1">{{'sale.satispay_pay_agreement_1' | translate: {'beneficiary': beneficiary} }}</div>
      <div>{{'sale.satispay_pay_agreement_2' | translate: {'beneficiary': beneficiary} }}</div>
      <div>{{'sale.satispay_pay_agreement_3' | translate}}</div>
      <div>{{'sale.satispay_pay_agreement_4' | translate}}</div>
    </div>

    <ng-container *ngIf="mode == 'pay' && saveMethodAvailable && stripeForm.controls['paymentMethodType'].value != PaymentMethodType.KLARNA && stripeForm.controls['paymentMethodType'].value != PaymentMethodType.SATISPAY">
      <div class="checkbox c-checkbox d-flex">
        <label for="saveMethod">
          <input type="checkbox" formControlName="saveMethod" id="saveMethod" />
          <span class="fa fa-check"></span>
        </label>
        <div class="d-inline-block" [innerHTML]="'payment_method.save_to_customer' | translate"></div>
      </div>

      <div class="method-agreement-panel mt-1 mb-3" *ngIf="stripeForm.controls['paymentMethodType'].value == PaymentMethodType.CARD">
        {{'payment_method.card_save_to_customer_agreement' | translate}}
      </div>
    </ng-container>

  </form>
</div>