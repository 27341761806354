<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
  <!-- START navbar header-->
  <div class="navbar-header">
    <a class="navbar-brand" href="#/">
      <div class="brand-logo">
        <img *ngIf="!pendingLogoSrc" class="logo" [src]="logoPath" alt="App Logo" />
        <img *ngIf="pendingLogoSrc" class="logo" [src]="pendingLogoSrc" alt="App Logo" />
      </div>
    </a>
  </div>
<ul class="navbar-nav mr-auto flex-row top-menu" [ngClass]="{'order-first':profile == 'RESELLER' || profile == 'RESPONSIBLE' ||  profile == 'AGENT'}">
    <li class="nav-item only-mobile">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize="" (click)="toggleCollapsedSideabar()"
        *ngIf="!isCollapsedText()">
        <em class="fas fa-bars"></em>
      </a>
      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a class="nav-link sidebar-toggle d-md-none" (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
        <em class="fas fa-bars"></em>
      </a>
    </li>
    <!-- END User avatar toggle-->
    <li class=" nav-item menu-item" routerLinkActive="active" *ngFor="let navItem of navItems">
      <a class="nav-link d-none d-md-block d-lg-block d-xl-block" [routerLink]="[navItem.link]">{{ navItem.name |
        translate }}</a>
    </li>
    <!--  -->
    <li class="nav-item menu-item select-lang-wrapper only-mobile">
      <div class="select-lang dropdown">
        <a href="#" class="dropdown-toggle current-lang" data-bs-toggle="dropdown" role="button" aria-expanded="false">
          <img class="img-responsive" src="assets/img/country/{{selectedLanguage || 'IT'}}.png" style="max-width: 30px;" />
          <span class="caret"></span>
        </a>
        <ul class="dropdown-menu arrow-center user-menu lang-list">
          <!-- <li *ngFor="let lang of avaibleLangs" class="lang-item"><a class="nav-link">{{ lang.text }}</a></li> -->
          <li *ngFor="let lang of avaibleLangs" class="lang-item" (click)="setLang(lang.code)">
            <a class="nav-link">
              <img class="img-responsive" src="assets/img/country/{{lang.code}}.png" style="max-width: 30px;" />
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
  <ul class="navbar-nav flex-row account-info">
    <li class="nav-item dropdown hide-mobile">
      <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" role="button" aria-expanded="false" style=" text-decoration: none;">
        <span class="user-name">{{user.name}}</span>
        <span class="caret"></span>
        <!-- <div class="user-image">
                    <img src="assets/img/country/en.png" class="flagIcon">
                </div> -->
      </a>
      <ul class="dropdown-menu arrow-center user-menu" role="menu">
        <!-- START Logout button-->
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/change-password']">{{'nav.change-password' | translate}}</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['/configuration']">{{'nav.configuration' | translate}}</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/user/profile']">{{'nav.user-profile' | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link logout" (click)="logout()">
            {{'nav.logout' | translate}}
          </a>
        </li>
        <!-- END Logout menu-->
      </ul>
    </li>
    <li class="nav-item only-mobile">
      <a class="nav-link logout" (click)="logout()">
        <em class="icon-logout"></em>
      </a>
    </li>
    <li class="nav-item hide-mobile">
      <div class="user-image">
        <!-- <img src="assets/img/logo-single.png" class="rounded-img"> -->
        <p class="capitals-header"> {{user.capitals}}</p>
      </div>

      <div class="select-lang dropdown">
        <a href="#" class="dropdown-toggle current-lang" data-bs-toggle="dropdown" role="button" aria-expanded="false">
          <img class="img-responsive" src="assets/img/country/{{selectedLanguage}}.png" style="max-width: 30px;" />
          <span class="caret"></span>
        </a>
        <ul class="dropdown-menu arrow-center user-menu lang-list" role="menu">
          <!-- <li *ngFor="let lang of avaibleLangs" class="lang-item"><a class="nav-link">{{ lang.text }}</a></li> -->
          <li *ngFor="let lang of avaibleLangs" class="lang-item" (click)="setLang(lang.code)">
            <a class="nav-link">
              <img class="img-responsive" src="assets/img/country/{{lang.code}}.png" style="max-width: 30px;" />
            </a>
          </li>
        </ul>
      </div>

      <div class="user-info light-bg">{{ 'nav.profile_role.'+profile | translate | uppercase }}</div>
    </li>
    <li class="nav-item menu-right" *ngIf="(router.url =='/home' || router.url =='/sales' || router.url =='/profile-rate' || router.url =='/user' ) && (profile=='SUPERADMIN' || profile=='RESELLER')"
      [ngClass]="{'hideOnDesktop': profile=='RESELLER'}"> 
      <a href="#menu-toggle" id="menu-toggle" (click)="toggleSidebarUtenti()">
        <div class="user-info light-bg m1"><i class="fa fa-outdent" aria-hidden="true"></i></div>
      </a>
    </li>
  </ul>
</nav>
